<template>
  <x-dialog
    size="s"
    no-cancel
    submit-text="Начать"
  >
    <div class="modal-body">
      <x-icon
        name="flask"
        class="modal-body__icon"
      />

      <heading level="3">
        Добро пожаловать в {{ companyDetails.name }}!
      </heading>

      <div class="modal-body__text">
        Приглашаем тебя присоединиться к масштабному бета-тестированию MMP. <br> <br>
        Для нас важно мнение каждого участника, оно поможет нам сделать максимально полезный сервис для продавцов. Самые активные участники получат бонусы от {{ companyDetails.name }}!
      </div>
      <br>

      <div class="modal-body__text">
        Добавьте магазины, чтобы приступить к бета-тестированию.
      </div>
    </div>
  </x-dialog>
</template>

<script>
import Heading from '@/components/Common/Heading.vue'
import companyDetails from '@/plugins/companyDetails'

export default {

  components: {
    Heading
  },
  data () {
    return {
      companyDetails
    }
  }
}
</script>

<style lang="stylus" scoped>
.modal-body
  text-align center

  &__text
    font-size: $font-sizes.text
    color: $colors.gray-darker

  &__icon
    width 60px
    height 60px
    color: $colors.gray
</style>

<template>
  <div class="beta">
    <heading>
      Бета-тестирование {{ companyDetails.platformName }}
    </heading>

    <beta-card-list />
  </div>
</template>

<script>
import Heading from '@/components/Common/Heading.vue'
import BetaCardList from '@/components/BetaTest/BetaCardList.vue'
import WelcomeModal from '@/components/BetaTest/WelcomeModal.vue'
import companyDetails from '@/plugins/companyDetails'

export default {
  components: {
    Heading,
    BetaCardList
  },

  data: () => ({
    companyDetails
  }),

  created () {
    const flag = JSON.parse(localStorage.getItem('seller_show_beta_welcome_modal'))
    if (flag ?? 1) {
      this.$dialog.show(WelcomeModal)
    }
    localStorage.setItem('seller_show_beta_welcome_modal', 0)
  }
}
</script>

<style lang="stylus" scoped>

</style>

export default Object.freeze([
  {
    title: 'Экспорт товаров между маркетплейсами',
    text: 'Выгружайте товары из Ozon, Wildberries и Яндекс.Маркет и заливайте их на AliExpress',
    href: process.env.VUE_APP_OZON2ALI_URL,
    thumbnail: require('@/assets/images/services/superstore.svg'),
    tracking: 'mmp_beta-test_o2a_start',
    feedback: 'mmp_beta-test_o2a_feedback',
    isDisabled: false
  },
  {
    title: 'Отчёт по оборачиваемости',
    text: 'Оптимизируйте свои поставки и сократите упущенную выручку на Wildberries, Ozon и AliExpress',
    caption: 'Для использования отчета по оборачиваемости',
    captionLink: 'добавьте магазин AliExpress',
    path: { name: 'product.list' },
    thumbnail: require('@/assets/images/services/pricing.svg'),
    tracking: 'mmp_beta-test_report_turnover_start',
    feedback: 'mmp_beta-test_report_turnover_feedback',
    isDisabled: false
  },
  {
    title: 'Добавление товаров в акции AliExpress',
    text: 'Удобный инструмент добавления товаров в акции AliExpress',
    caption: 'Для использования',
    captionLink: 'добавьте магазин',
    path: { name: 'promotion' },
    thumbnail: require('@/assets/images/services/marketing.svg'),
    tracking: 'mmp_beta-test_promotion_start',
    feedback: 'mmp_beta-test_promotion_feedback',
    isDisabled: false
  },
  {
    title: 'Внешняя аналитика',
    text: 'Анализируйте товары и конкурентов',
    subtext: 'Доступны Ozon и Wildberries',
    href: `${process.env.VUE_APP_ORM_URL}/sellers`,
    thumbnail: require('@/assets/images/services/external-analysis.svg'),
    tracking: 'mmp_beta-test_orm_sellers_start',
    feedback: 'mmp_beta-test_orm_sellers_feedback',
    isDisabled: false
  },
  {
    title: 'A/B/C анализ',
    text: 'Отслеживайте эффективность работы с товарами',
    subtext: 'Доступны Ozon и Wildberries',
    href: `${process.env.VUE_APP_INTERNAL_ANALYTICS_URL}/reports`,
    thumbnail: require('@/assets/images/services/abc.svg'),
    tracking: 'mmp_beta-test_analytics_reports_start',
    feedback: 'mmp_beta-test_analytics_reports_feedback',
    isDisabled: false
  },
  {
    title: 'Анализ поисковой и контентной выдачи',
    text: 'Изучайте влияние контента и размещения на вашу прибыль',
    href: `${process.env.VUE_APP_INTERNAL_ANALYTICS_URL}/products`,
    thumbnail: require('@/assets/images/services/search-content.svg'),
    isDisabled: true
  },
  {
    title: 'Анализ маркетинговых показателей',
    text: 'Погружайтесь детальнее в продажи, познакомьтесь со своим клиентом',
    href: `${process.env.VUE_APP_INTERNAL_ANALYTICS_URL}/products`,
    thumbnail: require('@/assets/images/services/marketing-data.svg'),
    isDisabled: true
  }
])

<template>
  <div>
    <ul class="beta-card-list">
      <li class="beta-card-list__item">
        <x-card class="beta-shop-card">
          <div class="beta-shop-card__icon-wrapper">
            <svg-icon
              class="beta-shop-card__icon"
              name="beta-shop"
            />
          </div>
          <h5 class="beta-shop-card__title">
            Добавьте магазин на платформу {{ companyDetails.platformName }}
          </h5>
          <x-btn
            v-bind="$props"
            prepend="plus"
            class="beta-shop-card__btn"
            @click="openAddShopModal"
          >
            Добавить магазин
          </x-btn>
        </x-card>
      </li>
      <li
        v-for="card in cards"
        :key="card.title"
        class="beta-card-list__item"
      >
        <beta-card
          :card="card"
          @open-add-shop-modal="openAddShopModal"
        />
      </li>
    </ul>
    <div
      class="beta-card-list beta-card-list--bottom"
    >
      <x-card
        class="card-bottom new-cards"
        :style="{ backgroundImage: 'url(' + thumbnail + ')' }"
      >
        <h3 class="new-cards__title">
          Новые карточки уже скоро!
        </h3>
      </x-card>

      <template v-if="isSocialShown">
        <x-card class="card-bottom">
          <div class="card-bottom__content">
            <h3
              class="card-bottom__title"
            >
              Наш Telegram-канал
            </h3>
            <p class="card-bottom__text">
              Подключайтесь к нашему Telegram-каналу, следите за новостями и оставляйте обратную связь
            </p>
            <x-btn
              ghost
              link-chevron
              href="https://t.me/xwaysellersclub"
            >
              Открыть
            </x-btn>
          </div>
          <svg-icon
            class="card-bottom__icon"
            name="tg"
            width="60"
            height="60"
          />
        </x-card>
        <x-card class="card-bottom">
          <div class="card-bottom__content">
            <h3
              class="card-bottom__title"
            >
              Наш RUTUBE-канал
            </h3>
            <p class="card-bottom__text">
              Обучающие видео по работе с сервисом
            </p>
            <x-btn
              ghost
              link-chevron
              :href="XWAY_RUTUBE_CHANNEL"
            >
              Перейти
            </x-btn>
          </div>
          <svg-icon
            class="card-bottom__icon"
            name="rutube"
            width="60"
            height="43"
          />
        </x-card>
      </template>
    </div>
    <add-marketplace-modal />
    <connect-modal @success="onShopConnected" />
  </div>
</template>

<script>
import cards from '@/constants/beta-test'

import BetaCard from '@/components/BetaTest/BetaCard.vue'
import AddMarketplaceModal from '@/components/Modals/AddMarketplaceModal'
import ConnectModal from '@/components/Modals/ConnectModal'
import { XWAY_RUTUBE_CHANNEL } from '@/constants/links'
import companyDetails from '@/plugins/companyDetails'
import currentTheme from '@/plugins/currentTheme'
import { getDefaultThemeName } from '@xway-team/theme-detector'

export default {
  components: {
    BetaCard,
    AddMarketplaceModal,
    ConnectModal
  },

  data () {
    return {
      cards,
      XWAY_RUTUBE_CHANNEL,
      companyDetails
    }
  },

  computed: {
    isSocialShown () {
      return currentTheme.name === getDefaultThemeName()
    },

    thumbnail () {
      return require('@/assets/images/svg/rich-content.svg')
    }
  },

  methods: {
    openAddShopModal () {
      this.$metrics.hit(this.$route.meta?.metricsEvents?.addShop?.add)
      this.$modal.show('add-marketplace-modal')
    },

    onShopConnected () {
      this.$modal.hide('connect-modal')
      this.$modal.hide('add-marketplace-modal')
    }
  }
}
</script>

<style lang="stylus" scoped>
  .beta-shop-card
    background: linear-gradient(90deg, #5B40FF 0%, #A040FF 100%)
    text-align center
    height 100%
    &__icon
      width 80px
      height 80px
      &-wrapper
        padding-top 150px
        margin-bottom 20px
    &__btn
      margin-top 20px
      ::v-deep .x-btn__content
        background #fff
        color #5B40FF
    &__title
      font-size 20px
      color #fff
  .new-cards
    background-repeat: no-repeat
    background-position: right top
    background-size: auto 100%
    align-items center
    &__title
      font-size 24px
      padding-right 120px
      text-align center

  .card-bottom
    display flex
    height 162px
    max-width 33%
    flex-grow 1
    &__title
      font-size 20px
    &__content
      padding-right 12px
      flex 1
    &__text
      color #3C3C47
      line-height 18px
    &__icon
      min-width 60px
      max-width 60px

  .beta-card-list
    display flex
    flex-wrap wrap
    list-style none
    gap 24px

    &--bottom
      margin-top 24px

    &__item
      min-width 264px
      flex-basis calc(100% / 4 - 18px)

    &__demo
      height 100%
      text-align center
</style>

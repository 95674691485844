<template>
  <x-card class="beta-card">
    <div class="beta-card__image-wrapper">
      <img
        class="beta-card__image"
        :src="card.thumbnail"
      >
    </div>

    <h5 class="beta-card__title">
      {{ card.title }}
    </h5>

    <div class="beta-card__text">
      <span class="beta-card__description">
        {{ card.text }}
      </span>
    </div>

    <div class="beta-card__additional-info">
      <p
        v-if="card.captionLink"
        class="beta-card__caption-link-section"
        @click="$emit('open-add-shop-modal')"
      >
        <span class="beta-card__caption-link">{{ card.caption }}</span>
        &nbsp;
        <button class="beta-card__caption-link beta-card__caption-link--underlined">
          {{ card.captionLink }}
        </button>
      </p>

      <p
        v-if="card.subtext"
        class="beta-card__subtext"
      >
        {{ card.subtext }}
      </p>
    </div>

    <div class="beta-card__actions">
      <template v-if="card.isDisabled">
        <x-btn disabled>
          СКОРО
        </x-btn>
      </template>
      <template v-else>
        <x-btn
          :to="card.path"
          :href="card.href"
          @click.native="onStart(card.tracking)"
        >
          Начать
        </x-btn>

        <x-btn
          outline
          @click.native="onFeedback(card.feedback)"
        >
          Обратная связь
        </x-btn>
      </template>
    </div>
  </x-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapActions: mapTrackingActions } = createNamespacedHelpers('tracking')
export default {
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapTrackingActions(['setEvent']),
    onStart (tracking) {
      this.setEvent({
        name: tracking,
        product: 'MMP',
        screen: 'mmp_beta-test'
      })
    },
    onFeedback (feedback) {
      this.setEvent({
        name: feedback,
        product: 'MMP',
        screen: 'mmp_beta-test'
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .beta-card
    display flex
    flex-direction column
    height: 100%

    &__title
      height 45px
      font-size: $font-sizes.headline-5
      margin-bottom 8px

    &__text
      color $colors.gray-darker
      margin-bottom 10px
      height: 52px

    &__additional-info {
      margin-bottom 20px
    }

    &__subtext
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #A3A3AC;

    &__actions
      margin-top auto
      display flex
      justify-content space-between

    &__caption-link {
      font-family $font-family
      text-align left
      color: $colors.orange-accent-4
      font-weight: $font-weights.semibold

      &--underlined {
        border-bottom 1px solid currentColor
        transition all .2s
        cursor pointer

        &:hover,
        &:active {
          opacity 0.7
          border-bottom 1px solid transparent
        }
      }
    }

    &__image-wrapper
      display flex
      justify-content center
      flex-shrink 0
      height 52%
      width calc(100% + 40px)
      margin-bottom 20px
      align-self center

    &__image {
      max-width 100%
      max-height 100%
    }
</style>
